window.console || (window.console = {
	log: function () { }
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	const siteName = 'hakutsuru';
	const htmlHasClass = (_class) => {
		return $('html').hasClass(_class);
	};
	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			isGlobal: location.pathname.indexOf('/global/'),
			region: location.pathname.indexOf('/global/') === -1 ? 'jp' : location.pathname.split('/')[2],
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			},
			brand: location.pathname.split('/')[1]
		},
		localDecision() {
			var regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll(`[class*="${key}"]`);
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done((...args) => {
					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		setAgeCheck() {
			const bekkaku_load = () => {
				// ロード完了の処理
				function loading_complite() {
					$('#l-bekkaku-loading').fadeOut(500);
					$('html, body').animate({
						scrollTop: 0
					}, 1);
				}
				// 5秒間ロードが終わらなかったら強制的に表示
				let delay_timer = setTimeout(function () {
					$(window).off('load');
					loading_complite();
				}, 5000);
				// ロード完了後表示
				$(window).on('load', function () {
					clearTimeout(delay_timer);
					loading_complite();
				});
			};

			if (!sessionStorage.getItem('ageCheck') && !document.cookie.split(';').some((item) => item.trim().startsWith('ageCheck='))) {
				let HTML = '<div class="ageCheck-overlay">' +
					'<div class="ageCheck-closeArea"></div>' +
					'<div class="ageCheck"><img src="/common/images/logo.svg" alt="白鶴 ロゴ" class="__logo">' +
					'<p class="__lead">このページはお酒に関する<br data-view="sp">情報が含まれておりますので、<br> 20歳未満の方はご覧いただけません。</p>' +
					'<p class="__main">あなたは<br data-view="sp">20歳以上ですか？</p>' +
					'<div class="pure-g"><div class="pure-u-1-2 pure-u-md-1">' +
					'<span class="__button js-ageCheck" data-target="true"><span>はい</span>' +
					'<svg class="icon"><use xlink:href="/common/svg/sprite.svg#arrow"></use></svg></span></div>' +
					'<div class="pure-u-1-2 pure-u-md-1">' +
					'<span class="__button js-ageCheck" data-target="false"><span>いいえ</span></span></div></div>' +
					'<div class="__check"><label for="ageCheckAgreement" class="__check-box">' +
					'<input type="checkbox" class="__check-input js-setCookie" name="ageCheckAgreement" id="ageCheckAgreement">' +
					'<span class="__check-text">次回から表示させない</span></label></div>' +
					'</div></div>';
				$('body').append(HTML);

				const $overlay = $('.ageCheck-overlay');
				$overlay.addClass('is-active');
				setTimeout(() => {
					$overlay.addClass('is-visible');

					// 「はい」クリック
					$('.js-ageCheck[data-target="true"]').on('click', (e) => {
						sessionStorage.setItem('ageCheck', 'true');

						$overlay.removeClass('is-visible');
						$('body').removeClass('is-fixed');
						e.preventDefault();
						setTimeout(() => {
							$overlay.removeClass('is-active');
							$overlay.remove();
						}, 400);

						// チェックされていたらcookie登録
						if ($('.js-setCookie').prop('checked')) {
							document.cookie = 'ageCheck=true; max-age=7776000; path=/';
						} else {
							document.cookie = 'ageCheck=; max-age=0';
						}

						// bekkaku：ロード処理
						if (FUNCTIONS.va.brand === 'bekkaku') {
							bekkaku_load();
						}
					});

					$('.js-ageCheck[data-target="false"]').on('click', (e) => {
						e.preventDefault();
						return false;
					});
				});

				$('body').addClass('is-fixed');
			} else {
				if (FUNCTIONS.va.brand === 'bekkaku') {
					bekkaku_load();
				}
			}
		},
		pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				var thisScroll = $this.scrollTop();

				if (thisScroll > '150') {
					$pagetop.addClass('is-visible');
				} else {
					$pagetop.removeClass('is-visible');
				}
			});

			$('.footer-pagetop').click((event) => {
				$('html,body').stop().animate({ scrollTop: 0 }, 1200, 'easeOutExpo');
				return false;
			});
		},
		loadDelayScript() {
			var _this = this;
			_this.pageTop();
			if(!(location.pathname.split('/')[1] === 'recruit')) {
				_this.setAgeCheck();
			}
		}
	}

	$(() => FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript());
})(window.jQuery);
